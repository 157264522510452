export default class Media {
    constructor() {
        this.container = document.querySelector('[data-media-dest]');

        if (this.container) {
            this.prepareThumbs();
        }
    }

    prepareThumbs() {
        this.thumbs = document.querySelectorAll('[data-media-thumbs] > li');

        if (!this.thumbs) {
            return false;
        }

        this.thumbs.forEach((thumb) => {
            thumb.addEventListener('click', (e) => {
                e.preventDefault();
                this.thumbClicked(thumb)
            });
        });
    }

    thumbClicked(thumb) {
        this.container.classList.add('loading');

        const type = thumb.dataset.mediaType ?? 'element';
        const mediaId = thumb.dataset.mediaId;

        if (type == 'image') {
            const imagePath = thumb.dataset.mediaPath;
            const img = new Image();

            img.onload = () => {
                this.toggleMedia(mediaId, img);
                this.container.classList.remove('loading');
            };

            img.src = imagePath;
            img.dataset.mediaId = mediaId;
        }

        if (type == 'element') {
            this.toggleMedia(mediaId);
            this.container.classList.remove('loading');
        }
    }

    toggleMedia(mediaId, element) {
        const currentItems = this.container.querySelectorAll('[data-media-id]');
        let injectNew = true;

        currentItems.forEach((item) => {
            const currentMediaId = item.dataset.mediaId;

            if (currentMediaId == mediaId) {
                item.style.display = 'block';
                injectNew = false;
            } else {
                item.style.display= 'none';
            }
        });

        if (element && injectNew) {
            this.container.appendChild(element);
        }
    }
}
