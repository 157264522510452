export default class Dropdown
{
    constructor() {
        this.menus = document.querySelectorAll('[data-dropdown]');

        if (!this.menus) {
            return false;
        }

        this.menus.forEach(menu => {
            const sub = menu.querySelector(menu.dataset.dropdown);

            menu.addEventListener('click', (e) => {
                menu.classList.toggle('open');
            });
        });
    }
}
