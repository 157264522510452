import Autocompleter from "./modules/autocompleter";
import Filters from "./modules/filters";
import FilterToggle from './modules/filter_toggle';
import Media from './modules/media';
import BlogGrid from "./modules/bloggrid";
import Navigation from "./modules/navigation";
import Carts from './modules/carts';
import Dropdown from "./modules/dropdown";

(function() {
    new Navigation('.main-navigation .products');
    new Autocompleter('#search-autocomplete');
    new Filters();
    new FilterToggle();
    new Media();
    new BlogGrid();
    new Carts();
    new Dropdown();
})();
