export default class Filters {

    constructor() {
        this.facetFilters();
    }

    facetFilters() {
        this.facets = document.querySelectorAll('.facet');

        this.facets.forEach((facet) => {
            let hasMore = false;

            let facetType = facet.dataset.type;
            let maxVisible = facet.dataset.maxVisible;
            let items = facet.querySelectorAll('li:not(.is-selected)');

            items.forEach((filter, index) => {
                if (index > maxVisible) {
                    hasMore = true;
                    filter.classList.add('hidden');
                }
            });

            if (hasMore) {
                const storageKey = 'filterState_'+ facetType;
                const more = document.createElement('a');
                const isToggled = localStorage.getItem(storageKey) == 'true';

                more.classList.add('more-filters');
                more.setAttribute('href', '#');
                more.innerHTML = facet.dataset.moreLabel;

                if (isToggled) {
                    facet.classList.add('expanded');
                    more.innerHTML = facet.dataset.lessLabel;
                }

                more.addEventListener('click', (e) => {
                    e.preventDefault();

                    if (facet.classList.contains('expanded')) {
                        localStorage.setItem(storageKey, false);
                        more.innerHTML = facet.dataset.moreLabel;
                        facet.classList.remove('expanded');
                    } else {
                        localStorage.setItem(storageKey, true);
                        more.innerHTML = facet.dataset.lessLabel;
                        facet.classList.add('expanded');
                    }

                });

                const moreWrapper = document.createElement('div');
                moreWrapper.classList.add('more-wrapper');
                moreWrapper.appendChild(more);

                facet.appendChild(moreWrapper);
            }
        });
    }
}
