import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";

export default class BlogGrid
{
    constructor()
    {
        this.masonry = document.querySelectorAll('.masonry');

        if (!this.masonry) {
            return false;
        }

        this.masonry.forEach(masonry => {
            imagesLoaded(masonry, () => {
                const iso = new Isotope(masonry, {
                    itemSelector: '.blog-teaser',
                    layoutMode: 'masonry',
                    masonry: {
                        columnWidth: '.blog-teaser-sizer'
                    }
                });

                window.addEventListener('resize', () => {
                    iso.layout();
                });

                masonry.classList.add('masonry-loaded');
            });
        });
    }
}
