export default class Carts {

    debounce(func, wait, immediate)
    {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    constructor()
    {
        var wrapper = $('#lists-table-wrapper');
        var form = $('#lists-table');
        var sidebar = $('.lists-sidebar');

        var focusTextareaId = null;
        var focusTextareaSelection = {};

        form.on('submit', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            wrapper.addClass('loading');

            $.ajax({
                cache: false,
                url: $(this).attr('action'),
                data: $(this).serialize(),
                method: 'post'
            }).done(function(response) {
                var content = $('#lists-table-wrapper', response);

                // on empty result
                if (!content.length) {
                    wrapper.removeClass('loading');
                    $('#list-stable-content').html(content.html());

                    return;
                }

                var postactions = $('#postactions', response);

                $('.total .price', sidebar).text(content.data('cartSum'));
                $('.cartinfo .total-items', sidebar).text(content.data('cartTotal'));
                $('#postactions').replaceWith(postactions);

                wrapper
                    .removeClass('loading')
                    .html(content.html());

                if (focusTextareaId) {
                    let txtareaEl = document.getElementById(focusTextareaId);
                    txtareaEl.focus();
                    txtareaEl.setSelectionRange(focusTextareaSelection.start, focusTextareaSelection.end);
                }
            });

            return false;
        });

        var blurTimer;
        form.on('change', 'input[type=number],input[type=checkbox]', this.debounce(function(e) {
            form.trigger('submit');
            focusTextareaId = null;
        }, 50));

        form.on('keypress', 'textarea', this.debounce(function(e) {
            focusTextareaId = e.target.getAttribute('id');
            focusTextareaSelection = {
                start: e.target.selectionStart,
                end: e.target.selectionEnd
            };

            form.trigger('submit');
        }, 800));
    }
}
