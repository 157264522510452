export default class FilterToggle {

    constructor() {
        this.storageKey = 'houtshop.facets.open';
        this.filterOpen = '';

        this.toggle = document.querySelector('.stikcy-filter-toggle');
        this.wrapper = document.getElementById('facet-wrapper');

        if (this.toggle) {

            if (this.isPersistedActive()) {
                this.openFacets();
            }

            this.toggle.addEventListener('click', (e) => {
                e.preventDefault();

                if (!this.toggle.classList.contains('active')) {
                    this.openFacets();
                } else {
                    this.closeFacets();
                }
            });
        }
    }

    isPersistedActive() {
        const state = localStorage.getItem(this.storageKey) ?? false;

        return JSON.parse(state) === true;
    }

    openFacets() {
        this.toggle.querySelector('span.label').innerHTML = this.toggle.dataset.filterActive;
        this.toggle.classList.add('active');
        this.wrapper.classList.add('visible');

        document.body.classList.add('filter-overlay-visible');

        localStorage.setItem(this.storageKey, true);
    }

    closeFacets() {
        this.toggle.querySelector('span.label').innerHTML = this.toggle.dataset.filterInactive;
        this.toggle.classList.remove('active');
        this.wrapper.classList.remove('visible');

        document.body.classList.remove('filter-overlay-visible');

        localStorage.setItem(this.storageKey, false);
    }

}
