/**
 * Port from old jquery navigation
 * Should be vanillized at some point
 */
import '../legacy/jquery.menu-aim';
import '../legacy/jquery.hoverintent';

import Isotope from "isotope-layout";

export default class Navigation
{
    constructor(activatorElement)
    {
        let options = {
            activator: activatorElement,
            selector: '.doormat',
            activeClass: 'active'
        };

        this.gridItems = document.querySelectorAll('.doormat-sub .grid');

        let menu = $(options.selector);
        let activator = $(options.activator);
        let menuHeight = 0;
        let opened = false;
        let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        let masonries = [];

        this.gridItems.forEach(grid => {
            const iso = new Isotope(grid, {
                itemSelector: '.category-box',
                layoutMode: 'masonry',
                transitionDuration: 0,
                percentPosition: true,
                sortBy : [ 'weight' ],
                sortAscending: true,
                getSortData: {
                    weight: '[data-weight]'
                }
            });

            masonries.push(iso);
        });

        // enable menuaim interaction
        menu.menuAim({
            activate: function(element) {
                if (!menuHeight) {
                    menuHeight = menu.outerHeight(); // initial is set on first activation
                }

                var element = $(element),
                    sub =  $('.doormat-sub', element);

                element.addClass(options.activeClass);
                sub.show();

                masonries.forEach(grid => {
                    grid.layout();
                });

                var subHeight = sub.outerHeight();

                if (subHeight > menuHeight) {
                    menu.height(subHeight);
                } else {
                    menu.height(menuHeight);
                }
            },
            deactivate: function(element) {
                $(element).removeClass(options.activeClass);
                $('.doormat-sub', element).hide();
            },
            rowSelector: '.groups > li',
        });

        // toggle the doormat menu
        var activatorActive = false;

        var initialOpen = function() {
            menu.addClass('open');
            activator.addClass(options.activeClass);

            if (!opened) {
                menu.find('.groups > li:first-child').trigger('mouseenter');
                opened = true;
            }
        }

        if (isMobile) {
            activator.on('click', function(event) {
                event.preventDefault();
                if (activator.hasClass(options.activeClass)) {
                    menu.removeClass('open');
                    activator.removeClass(options.activeClass);
                    opened = false;
                } else {
                    initialOpen();
                }
            });
        }
        else {
            activator
                .on('mouseenter', function() {
                    activatorActive = true;
                })
                .on('mouseleave', function() {
                    activatorActive = false;
                })
                .hoverIntent(function() {
                    if (activatorActive) {
                        initialOpen();
                    }
                });
        }

        var ltimer;
        var hideDoormat = function() {
            clearTimeout(ltimer);
            ltimer = setTimeout(function() {
                if (activatorActive) {
                    return false;
                }

                menu.removeClass('open');
                activator.removeClass(options.activeClass);
            }, 300);
        };

        menu.on('mouseenter', function() {
            clearTimeout(ltimer);
        });

        menu.on('mouseleave', hideDoormat);

        if (isMobile) {
            menu.find('.groups > li > a').on('click', function(e) {
                e.preventDefault();
            });
        }

        // make sure to hide the doormat when another menu item is hovered
        $('.main-navigation > li > a:not(.products)').on('mouseenter', function() {
            menu.removeClass('open');
            activator.removeClass('active');
        });
    }
}
