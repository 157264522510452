import autoComplete from '@tarekraafat/autocomplete.js';

export default class Autocompleter {

    constructor(elementSelector) {

        this.autocompleter = new autoComplete({
            selector: elementSelector,
            placeholder: 'Zoek artikelen',
            diacritics: false,
            // searchEngine: (query, record) => {
            //     return record;
            // },
            searchEngine: 'loose',
            data: {
                keys: [ "name" ],
                src: async (query) => {
                    const source = await fetch('/products_index/autocomplete.json?q='+ query)
                    const data = await source.json()

                    return data.data;
                }
            },
            resultsList: {
                maxResults: 200,
                element: (list, data) => {
                    if (!data.results.length) {
                        // Create "No Results" message element
                        const message = document.createElement("li");
                        // Add class to the created element
                        message.setAttribute("class", "no-result");
                        // Add message text content
                        message.innerHTML = `<span>Geen resultaten voor <strong>"${data.query}"</strong></span>.<span class="more">Ga verder voor alle resultaten...</span>`;
                        // Append message element to the results list
                        list.prepend(message);
                    }
                },
                noResults: true
            },
            resultItem: {
                element: (item, data) => {
                    item.classList.add('type-' + data.value.type);
                }
            }
        });

        let isNavigating = false;

        this.autocompleter.input.addEventListener('selection', (e) => {
            if (e.detail.results.length) {
                document.location.href = e.detail.selection.value.path;
            } else {
                document.getElementById('searchForm').submit();
            }
        });

        this.autocompleter.input.addEventListener('navigate', (e) => {
            if (e.detail.results.length) {
                isNavigating = true;

                let value = e.detail.selection.value.value;
                if (e.detail.selection.value.type == 'all') {
                    value = '';
                }

                this.autocompleter.input.value = value;
            }
        });

        this.autocompleter.input.addEventListener('keydown', (e) => {
            if (e.key == 'Enter' && !isNavigating) {
                document.getElementById('searchForm').submit();
            }
        });

    }
}
